<!-- 修改密码 -->
<template>
	<div class="changePassword-page">
		<div class="nav-title">修改密码</div>
		<div class="content flex-jc">
			<el-form ref="form" status-icon :model="form" :rules="rules" label-width="80px" hide-required-asterisk>
				<el-form-item label="原密码" prop="oldPassword">
					<el-input type="password" autocomplete="off" v-model="form.oldPassword"></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="newPassword">
					<el-input type="password" autocomplete="off" v-model="form.newPassword"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="checkNewPassword">
					<el-input type="password" autocomplete="off" v-model="form.checkNewPassword"></el-input>
				</el-form-item>
				<el-form-item class="btn-wrap">
					<el-button type="primary" @click="onSubmit">确定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import { $updatePassword } from "../../services/centerInfo";
export default {
	name: "ChangePassword",
	data() {
		var validatePass = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请输入密码"));
			} else {
				if (this.form.checkNewPassword !== "") {
					this.$refs.form.validateField("checkNewPassword");
				}
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请再次输入密码"));
			} else if (value !== this.form.newPassword) {
				callback(new Error("两次输入密码不一致!"));
			} else {
				callback();
			}
		};
		return {
			form: {
				oldPassword: "",
				newPassword: "",
				checkNewPassword: "",
			},
			rules: {
				oldPassword: [{ required: true, message: "请输入原密码", trigger: "blur" }],
				newPassword: [{ validator: validatePass, trigger: "blur" }],
				checkNewPassword: [{ validator: validatePass2, trigger: "blur" }],
			},
		};
	},
	components: {},

	computed: {},

	created() {},

	methods: {
		async onSubmit() {
			this.$refs.form.validate(async valid => {
				if (valid) {
					try {
						const data = await $updatePassword({
							old_upassword: this.form.oldPassword,
							new_upassword: this.form.newPassword,
							confirm: this.form.checkNewPassword,
						});
						this.$message.success("修改成功");
						console.log(data);
					} catch (error) {
						this.$message.error("修改失败");
					}
				} else {
					this.$message.error("请检查是否全部填写完成或两次密码是否填写一致");
				}
			});
		},
	},
};
</script>

<style scoped lang="less">
.nav-title {
	height: 100px;
	padding-top: 35px;
	line-height: 65px;
	border-bottom: 1px solid #eeefef;
	font-size: 18px;
	font-weight: bold;
	color: #1c1f21;
}
.content {
	min-height: 400px;
	padding-top: 80px;
	.el-form {
		width: 400px;
	}
}
.btn-wrap {
	display: flex;
	justify-content: center;
	.el-button {
		width: 180px;
	}
}
</style>
